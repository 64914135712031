import { useHTTP, usePermissionAPI } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pond } from "protobuf-ts/pond";
import { or } from "utils/types";
import { User, binScope } from "models";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";
import { dateRange } from "providers/http";
import { useGlobalState } from "providers";
import { quack } from "protobuf-ts/quack";

export interface IBinAPIContext {
  addBin: (bin: pond.BinSettings) => Promise<AxiosResponse<pond.AddBinResponse>>;
  updateBin: (key: string, bin: pond.BinSettings) => Promise<AxiosResponse<pond.UpdateBinResponse>>;
  updateBinStatus: (
    key: string,
    binStatus: pond.BinStatus
  ) => Promise<AxiosResponse<pond.UpdateBinResponse>>;
  removeBin: (key: string) => Promise<AxiosResponse<pond.RemoveBinResponse>>;
  getBin: (key: string) => Promise<AxiosResponse<pond.Bin>>;
  addComponent: (
    bin: string,
    device: number,
    component: string,
    preferences?: pond.BinComponentPreferences
  ) => Promise<AxiosResponse<pond.AddBinComponentResponse>>;
  removeComponent: (
    bin: string,
    component: string
  ) => Promise<AxiosResponse<pond.RemoveBinComponentResponse>>;
  removeAllComponents: (bin: string) => Promise<AxiosResponse<pond.RemoveAllBinComponentsResponse>>;
  updateComponentPreferences: (
    bin: string,
    component: string,
    preferences: pond.BinComponentPreferences
  ) => Promise<AxiosResponse<pond.UpdateBinComponentPreferencesResponse>>;
  getBinPageData: (binKey: string, userKey: string, showErrors?: boolean) => Promise<AxiosResponse>;
  listBins: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean,
    numerical?: boolean
  ) => Promise<AxiosResponse<pond.ListBinsResponse>>;
  listBinsAndData: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean,
    keys?: string[],
    types?: string[]
  ) => Promise<AxiosResponse<pond.ListBinsAndDataResponse>>;
  listHistory: (
    id: string,
    limit: number,
    offset: number
  ) => Promise<AxiosResponse<pond.ListBinHistoryResponse>>;
  listHistoryBetween: (
    id: string,
    limit: number,
    start: string,
    end: string
  ) => Promise<AxiosResponse<pond.ListBinHistoryResponse>>;
  listBinStatus: (
    key: string,
    startDate: any,
    endDate: any,
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    asRoot?: boolean
  ) => Promise<AxiosResponse<pond.ListBinStatusResponse>>;
  listBinComponents: (key: string) => Promise<AxiosResponse<pond.ListBinComponentsResponse>>;
  listBinComponentsMeasurements: (
    key: string,
    start: string,
    end: string,
    showErrors?: boolean
  ) => Promise<AxiosResponse<pond.ListBinComponentsMeasurementsResponse>>;
  updateBinPermissions: (
    key: string,
    users: User[]
  ) => Promise<AxiosResponse<pond.UpdateBinResponse>>;
  getBinMetrics: (search?: string) => Promise<AxiosResponse<pond.GetBinMetricsResponse>>;
  listBinLiters: (
    key: string,
    start: string,
    end: string,
    moisture: number,
    plenum: string,
    cable: string,
    pressure: string,
    fan?: string // the fan controller is optional so that if they don't have one the fan is assumed on
  ) => Promise<AxiosResponse<pond.ListBinLiterResponse>>;
  listBinPrefs: (key: string) => Promise<AxiosResponse<pond.ListBinComponentPreferencesResponse>>;
  updateTopNodes: (
    key: string,
    newTopNodes: pond.NewTopNode[]
  ) => Promise<AxiosResponse<pond.UpdateTopNodesResponse>>;
  listBinMeasurements: (
    key: string,
    startDate: any,
    endDate: any,
    limit: number,
    offset: number,
    order: string,
    measurementType?: number | quack.MeasurementType,
    keys?: string[],
    types?: string[],
    showErrors?: boolean
  ) => Promise<AxiosResponse<pond.ListObjectMeasurementsResponse>>;
}

export const BinAPIContext = createContext<IBinAPIContext>({} as IBinAPIContext);

interface Props {}

export default function DeviceProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, post, put, del } = useHTTP();
  const permissionAPI = usePermissionAPI();
  const [{ as }] = useGlobalState();

  const addBin = (bin: pond.BinSettings) => {
    if (as) return post<pond.AddBinResponse>(pondURL("/bins?as=" + as), bin);
    return post<pond.AddBinResponse>(pondURL("/bins"), bin);
  };

  const updateBin = (key: string, bin: pond.BinSettings) => {
    if (as) return put<pond.UpdateBinResponse>(pondURL("/bins/" + key + "?as=" + as), bin);
    return put<pond.UpdateBinResponse>(pondURL("/bins/" + key), bin);
  };

  const updateBinStatus = (key: string, binStatus: pond.BinStatus) => {
    if (as)
      return put<pond.UpdateBinStatusResponse>(
        pondURL("/bins/" + key + "/status?as=" + as),
        binStatus
      );
    return put<pond.UpdateBinStatusResponse>(pondURL("/bins/" + key + "/status"), binStatus);
  };

  const removeBin = (key: string) => {
    if (as) return del<pond.RemoveBinResponse>(pondURL("/bins/" + key + "?as=" + as));
    return del<pond.RemoveBinResponse>(pondURL("/bins/" + key));
  };

  const getBin = (key: string) => {
    if (as) return get<pond.Bin>(pondURL("/bins/" + key + "?as=" + as));
    return get<pond.Bin>(pondURL("/bins/" + key));
  };

  const addComponent = (
    bin: string,
    device: number,
    component: string,
    preferences?: pond.BinComponentPreferences
  ) => {
    let url = "/bins/" + bin + "/addComponent/" + device + "/" + component;
    if (as) {
      url =
        url +
        "?as=" +
        as +
        (preferences ? "&binPref=" + preferences.type + "&fillNode=" + preferences.node : "");
    } else if (preferences) {
      url = url + "?binPref=" + preferences.type + "&fillNode=" + preferences.node;
    }
    return post<pond.AddBinComponentResponse>(pondURL(url));
  };

  const removeComponent = (bin: string, component: string) => {
    let url = "/bins/" + bin + "/removeComponent/" + component;
    if (as) url = url + "?as=" + as;
    return post<pond.RemoveBinComponentResponse>(pondURL(url));
  };

  const removeAllComponents = (bin: string) => {
    let url = "/bins/" + bin + "/removeAllComponents";
    if (as) url = url + "?as=" + as;
    return post<pond.RemoveAllBinComponentsResponse>(pondURL(url));
  };

  const updateComponentPreferences = (
    bin: string,
    component: string,
    preferences: pond.BinComponentPreferences
  ) => {
    let url = "/bins/" + bin + "/updateComponent/" + component + "/preferences";
    if (as) url = url + "/?as=" + as;
    interface request {
      preferences: Object;
    }
    let body: request = { preferences: pond.BinComponentPreferences.toObject(preferences) };
    return put<pond.UpdateBinComponentPreferencesResponse>(pondURL(url), body);
  };

  const getBinPageData = (binKey: string, userKey: string, showErrors = true) => {
    return get(
      pondURL(
        "/bins/" +
          binKey +
          "/users/" +
          userKey +
          "?as=" +
          as +
          (showErrors ? "&showErrors=true" : "&showErrors=false")
      )
    );
  };

  const listBins = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean,
    numerical?: boolean
  ) => {
    return get<pond.ListBinsResponse>(
      pondURL(
        "/bins" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + or(order, "asc")) +
          ("&by=" + or(orderBy, "key")) +
          (numerical ? "&numerical=true" : "") +
          (as ? "&as=" + as : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          (search ? "&search=" + search : "")
      )
    );
  };

  const listBinsAndData = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    as?: string,
    asRoot?: boolean,
    keys?: string[],
    types?: string[]
  ) => {
    return get<pond.ListBinsAndDataResponse>(
      pondURL(
        "/binsAndData" +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + or(order, "asc")) +
          ("&by=" + or(orderBy, "key")) +
          (as ? "&as=" + as : "") +
          (asRoot ? "&asRoot=" + asRoot.toString() : "") +
          (search ? "&search=" + search : "") +
          (keys ? "&keys=" + keys.join(",") : "") +
          (types ? "&types=" + types.join(",") : "")
      )
    );
  };

  const listBinStatus = (
    key: string,
    startDate: any,
    endDate: any,
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    asRoot?: boolean
  ) => {
    return get<pond.ListBinStatusResponse>(
      pondURL(
        "/bins/" +
          key +
          "/status" +
          dateRange(startDate, endDate) +
          "?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + or(order, "asc")) +
          (asRoot ? "&asRoot=" + asRoot.toString() : "")
      )
    );
  };

  const listBinComponents = (key: string) => {
    return get<pond.ListBinComponentsResponse>(pondURL("/bins/" + key + "/components"));
  };

  const listBinComponentsMeasurements = (
    key: string,
    start: string,
    end: string,
    showErrors = false
  ) => {
    return get<pond.ListBinComponentsMeasurementsResponse>(
      pondURL(
        "/bins/" +
          key +
          "/components/measurements?start=" +
          start +
          "&end=" +
          end +
          "&as=" +
          as +
          (showErrors ? "&showErrors=true" : "&showErrors=false")
      )
    );
  };

  const listHistory = (id: string, limit: number, offset: number) => {
    return get<pond.ListBinHistoryResponse>(
      pondURL("/bins/" + id + "/history?limit=" + limit + "&offset=" + offset)
    );
  };

  const listHistoryBetween = (id: string, limit: number, start: string, end: string) => {
    return get<pond.ListBinHistoryResponse>(
      pondURL("/bins/" + id + "/historyBetween?limit=" + limit + "&start=" + start + "&end=" + end)
    );
  };

  const updateBinPermissions = (key: string, users: User[]) => {
    return permissionAPI.updatePermissions(binScope(key.toString()), users);
  };

  const getBinMetrics = (search?: string) => {
    if (as) {
      if (search)
        return get<pond.GetBinMetricsResponse>(
          pondURL("/metrics/bins/?search=" + search + "&as=" + as)
        );
      return get<pond.GetBinMetricsResponse>(pondURL("/metrics/bins/?as=" + as));
    }
    return get<pond.GetBinMetricsResponse>(
      pondURL("/metrics/bins" + (search ? "?search=" + search : ""))
    );
  };

  const listBinLiters = (
    key: string,
    start: string,
    end: string,
    moisture: number,
    plenum: string,
    cable: string,
    pressure: string,
    fan?: string
  ) => {
    if (as) {
      return get<pond.ListBinLiterResponse>(
        pondURL(
          "/bins/" +
            key +
            "/liters?start=" +
            start +
            "&end=" +
            end +
            "&moisture=" +
            moisture +
            "&plenum=" +
            plenum +
            "&cable=" +
            cable +
            "&pressure=" +
            pressure +
            (fan ? "&fan=" + fan : "") +
            "&as=" +
            as
        )
      );
    }
    return get<pond.ListBinLiterResponse>(
      pondURL(
        "/bins/" +
          key +
          "/liters?start=" +
          start +
          "&end=" +
          end +
          "&moisture=" +
          moisture +
          "&plenum=" +
          plenum +
          "&cable=" +
          cable +
          "&pressure=" +
          pressure +
          (fan ? "&fan=" + fan : "")
      )
    );
  };

  const listBinPrefs = (key: string) => {
    return get<pond.ListBinComponentPreferencesResponse>(
      pondURL("/bins/" + key + "/componentPreferences?as=" + as)
    );
  };

  const updateTopNodes = (key: string, newTopNodes: pond.NewTopNode[]) => {
    let body: pond.TopNodeList = pond.TopNodeList.create({ newTopNodes: newTopNodes });
    return post<pond.UpdateTopNodesResponse>(
      pondURL("/bins/" + key + "/updateTopNodes" + (as ? "?as=" + as : "")),
      body
    );
  };

  const listBinMeasurements = (
    key: string,
    startDate: any,
    endDate: any,
    limit: number,
    offset: number,
    order: string,
    measurementType?: number | quack.MeasurementType,
    keys?: string[],
    types?: string[],
    showErrors?: boolean
  ) => {
    const url = pondURL(
      "/objects/" +
        key +
        "/1/unitMeasurements" +
        dateRange(startDate, endDate) +
        "&limit=" +
        limit +
        "&offset=" +
        offset +
        "&order=" +
        order +
        (measurementType ? "&type=" + measurementType : "") +
        (as ? "&as=" + as : "") +
        (keys ? "&keys=" + keys : "") +
        (types ? "&types=" + types : "") +
        (showErrors ? "&showErrors=true" : "&showErrors=false")
    );
    return get<pond.ListObjectMeasurementsResponse>(url);
  };

  return (
    <BinAPIContext.Provider
      value={{
        addBin,
        updateBin,
        updateBinStatus,
        removeBin,
        listBinComponents,
        listBinComponentsMeasurements,
        listBinStatus,
        getBin,
        addComponent,
        removeComponent,
        removeAllComponents,
        updateComponentPreferences,
        getBinPageData,
        listBins,
        listBinsAndData,
        listHistory,
        updateBinPermissions,
        getBinMetrics,
        listBinLiters,
        listHistoryBetween,
        listBinPrefs,
        updateTopNodes,
        listBinMeasurements
      }}>
      {children}
    </BinAPIContext.Provider>
  );
}

export const useBinAPI = () => useContext(BinAPIContext);
